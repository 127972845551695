import React from 'react'

const CenterImage = ({ imageUrl }) => {

  return (
    <div className="hidden lg:flex absolute w-full h-full justify-center items-center">
      <div className="cursor-pointer w-3/5 h-2/5" style={{maxWidth: `30%`}}>
        <img
          className="m-h-full m-w-full"
          src={imageUrl} />
      </div>
    </div>
  )
}

export default CenterImage
