import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGitlab, faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'
import classNames from 'classnames'

const MobileMenu = ({
  linkedIn,
  github,
  gitlab,
  phone,
  email,
  imageUrl,
  isMenuOpen,
  handleMenuClick
}) => {

  const menuClasses = classNames({
    block: true,
    absolute: true,
    'top-0': true,
    'lg:hidden': true,
    'h-full': true,
    'text-lg': true,
    transition: true,
    flex: true,
    'right-0': isMenuOpen,
    '-right-300': !isMenuOpen,
  })

  const menuTriggerText = isMenuOpen ? `close` : `menu`

  return (
    <div className={menuClasses}>
      <div onClick={(e) => handleMenuClick(!isMenuOpen)} className="pr-4 pt-4 cursor-pointer top-0 right-0 fixed md:relative">
        <span>{menuTriggerText}</span>
      </div>
      <div className="bg-white w-300 shadow">
        <div className="flex flex-col items-center justify-center h-full pb-24">
          <div className="m-4 mb-8">
            <img src={imageUrl} />
          </div>
          <p>{phone}</p>
          <p>{email}</p>
          <div className="mt-4 w-4/5 flex justify-around">
            <a target="_blank" href={linkedIn}><FontAwesomeIcon icon={faLinkedinIn} /></a>
            <a target="_blank" href={github}><FontAwesomeIcon icon={faGithub} /></a>
            <a target="_blank" href={gitlab}><FontAwesomeIcon icon={faGitlab} /></a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileMenu
