import React, { useState } from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import '../styles/main.css'
import ProjectCard from '../components/ProjectCard'
import SocialIcons from '../components/SocialIcons'
import CenterImage from '../components/CenterImage'
import MobileMenu from '../components/MobileMenu'
import classNames from 'classnames'
import isNil from 'lodash.isnil'
import { animateScroll } from 'react-scroll'

const handleScrollClick = () => {

  animateScroll.scrollToTop({
    containerId: `project-wrapper`
  })
}

const RootIndex = (props) => {

  const [ isMenuOpen, setIsMenuOpen ] = useState(false)
  const [ isImageActive, setIsImageActive ] = useState(false)

  const handleMenuClick = (isOpen) => {

    setIsMenuOpen(isOpen)
  }

  const closeMenu = () => {

    setIsMenuOpen(false)
  }

  const handleImageEnter = () => {

    setIsImageActive(true)
  }

  const handleImageLeave = () => {

    setIsImageActive(false)
  }

  const handleImageClick = () => {

    setFeatureImage(getRandomImage(selectedFeatureImage.title))
  }

  const getRandomImage = (currentImageTitle) => {

    let randomImage = contentfulHomepage.featureImages.filter(image => image.title !== currentImageTitle)[Math.floor(Math.random() * contentfulHomepage.featureImages.length - 1)]

    if (isNil(randomImage)) {

      return getRandomImage(currentImageTitle)
    }

    return randomImage
  }

  const siteTitle = get(props, 'data.site.siteMetadata.title')
  const contentfulHomepage = get(props, 'data.contentfulHomepage')
  const allContentfulProject = get(props, 'data.allContentfulProject')
  const phone = contentfulHomepage.phone
  const phoneSecondary = contentfulHomepage.phoneSecondary
  const email = contentfulHomepage.email

  const randomImage = contentfulHomepage.featureImages[Math.floor(Math.random() * contentfulHomepage.featureImages.length)]
  const featureImage = contentfulHomepage.featureImages[0]

  const [ selectedFeatureImage, setFeatureImage ] = useState(featureImage)

  const ProjectList = allContentfulProject.nodes.map(project => <ProjectCard key={project.slug} project={project} />)

  const { linkedIn, github, gitlab } = contentfulHomepage

  const randomTextClasses = classNames({
    'text-md': true,
    'pt-2': true,
    'top-0': true,
    'right-0': true,
    absolute: isImageActive,
    hidden: !isImageActive
  })

  return (
    <div>
      <Helmet title={siteTitle} />
      <main
        className="flex flex-col lg:flex-row h-screen">
        <article
          onClick={() => closeMenu()}
          className="flex flex-0 relative mb-0 lg:mb-2 m-2 mr-4 ml-4 lg:flex-1 lg:m-4">
          <h1 className="text-3xl">{siteTitle}</h1>
          <p className={randomTextClasses}>random</p>
          <CenterImage
            handleEnter={() => handleImageEnter()}
            handleLeave={() => handleImageLeave()}
            handleClick={() => handleImageClick()}
            imageUrl={selectedFeatureImage.file.url} />
          <div className="hidden lg:block contact-wrapper absolute bottom-0 left-0 text-sm">
            <p>{phone}</p>
            <p>{email}</p>
          </div>
          <SocialIcons
            linkedIn={linkedIn}
            github={github}
            gitlab={gitlab}
          />
        </article>
        <article
          onClick={() => closeMenu()}
          id="project-wrapper" className="flex flex-col flex-1 mt-0 lg:mt-4 m-4 overflow-y-auto">
          {ProjectList}
        </article>
        <MobileMenu
            isMenuOpen={isMenuOpen}
            handleMenuClick={handleMenuClick}
            imageUrl={randomImage.file.url}
            linkedIn={linkedIn}
            github={github}
            gitlab={gitlab}
            phone={phone}
            phoneSecondary={phoneSecondary}
            email={email}
          />
      </main>
    </div>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
		contentfulHomepage {
      title
      description {
        json
      }
      email
      phone
      phoneSecondary
      linkedIn
      github
      gitlab
      featureImages {
        file {
          url
        }
        title
      }
    }
  	allContentfulProject {
      nodes {
        name
        slug
        url
        description
        gif {
          file {
            url
          }
          title
        }
        screenshot {
          file {
            url
          }
          title
        }
        tags
      }
    }
  }
`
