import React from 'react'
import isNil from 'lodash.isnil'

const ProjectCard = ({ project }) => {

  const { gif, name, screenshot, url } = project

  const assetUrl = !isNil(gif) ? gif.file.url : screenshot.file.url

  return (
    <div>
      <a href={url} target="_blank" className="block background-hover-target shadow-lg relative m-4 sm:m-8 md:m-16">
        <div className="cursor-pointer absolute absolute-center w-full h-full justify-center items-center flex background-hover text-4xl">
          <span>{name}</span>
        </div>
        <img src={assetUrl} />
      </a>
    </div>
  )
}

export default ProjectCard
