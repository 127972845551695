import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGitlab, faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'

const SocialIcons = ({ linkedIn, github, gitlab }) => {

  return (
    <div className="social-wrapper hidden lg:block absolute bottom-0 right-0 text-lg">
      <a target="_blank" href={linkedIn}><FontAwesomeIcon icon={faLinkedinIn} /></a>
      <a target="_blank" href={github}><FontAwesomeIcon icon={faGithub} /></a>
      <a target="_blank" href={gitlab}><FontAwesomeIcon icon={faGitlab} /></a>
    </div>
  )
}

export default SocialIcons
